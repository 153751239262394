import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

const VideoLoaderBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  marginRight: theme.spacing(2),
  width: '100%',
  maxWidth: '374px',
  minWidth: '250px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

export default VideoLoaderBox;
