import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

export const SearchResultsBox = styled('div')(
  ({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    overflowY: 'scroll',
    overflowX: 'hidden',
  })
);

export const VideosLayer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const NoResults = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}));
