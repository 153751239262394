import React from 'react';
import { SvgIcon } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ArrowDownIcon(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="24" height="24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M18.7076 10.2929C19.0981 10.6834 19.0981 11.3166 18.7076 11.7071L12.7076 17.7071C12.3171 18.0976 11.6839 18.0976 11.2934 17.7071L5.29338 11.7071C4.90286 11.3166 4.90286 10.6834 5.29338 10.2929C5.68391 9.90237 6.31707 9.90237 6.70759 10.2929L12.0005 15.5858L17.2934 10.2929C17.6839 9.90237 18.3171 9.90237 18.7076 10.2929Z"
        fill="#9BA8BB"
      />
    </SvgIcon>
  );
}

export default ArrowDownIcon;
