import React from 'react';
import DropdownMenu from 'components/DropdownMenu';
import { Category } from 'types';
import { MenuBox, MenuButton, BigMenu, SmallMenu } from './styles';

interface Props {
  categories: Category[];
}

function ExpandedMenu({ categories }: Props) {
  return (
    <MenuBox>
      <SmallMenu>
        <DropdownMenu categories={categories} />
      </SmallMenu>

      <BigMenu>
        {categories?.map((cat) => (
          <MenuButton
            variant="text"
            type="button"
            color="secondary"
            key={cat.name}
            href={`/categories/${cat.name.toLowerCase()}`}
          >
            {cat.name}
          </MenuButton>
        ))}
      </BigMenu>

      <MenuButton variant="text" type="button" color="secondary" href="/about">
        About
      </MenuButton>
    </MenuBox>
  );
}

export default ExpandedMenu;
