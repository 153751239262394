import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Category } from 'types';
import { JWPPlaylistListResponse } from 'models/generated';
import API from 'apis';
import { useAuth } from 'router';
import { mergeCategories } from 'utils/consts';
import App from 'components/App';
import Header from 'components/Header';
import Footer from 'components/Footer';
import OutletBox from './styles';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

async function playlistsLoader(
  token: string,
  setOpen: (open: boolean) => void
): Promise<JWPPlaylistListResponse> {
  const response = await fetch(API.getPlaylists(), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response?.ok) {
    setOpen(true);
    throw new Error(
      `status: ${response.status}, type: ${response.type}, text: "${response.statusText}"`
    );
  }

  const playlists = await response.json();

  return playlists;
}

function Layout() {
  const { token } = useAuth();
  const [categories, setCategories] = useState<Category[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const start = performance.now();
    playlistsLoader(token, setOpen)
      .then((res) => {
        if (res) {
          setCategories(mergeCategories(res));
        }
      })
      .catch((error) => {
        Sentry.setContext('request data', {
          time: `${performance.now() - start}ms`,
          info: error,
        });
        Sentry.captureMessage('Failed to fetch playlists', { level: 'error' });
      });
  }, [token]);

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <App>
      <Header categories={categories} />
      <OutletBox>
        <Outlet context={[categories, setCategories]} />
      </OutletBox>
      <Footer categories={categories} />
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error">
          Please check your connection
        </Alert>
      </Snackbar>
    </App>
  );
}

export default Layout;
