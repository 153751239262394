import { Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

export const HeaderBox = styled('div')(({ theme }: { theme: Theme }) => ({
  background: theme.palette.primary.light,
  borderBottom: `1px solid ${theme.border.header}`,
  display: 'flex',
  paddingBottom: theme.spacing(1),
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const Logo = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(2),
}));

export const BurgerMenuStyled = styled('div')(
  ({ theme }: { theme: Theme }) => ({
    display: 'none',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  })
);

export const MainMenuStyled = styled('div')(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const ModalBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: theme.spacing(2),
  background: theme.palette.background.paper,
  flexDirection: 'column',
}));

export const Input = styled(TextField)(() => ({
  margin: 0,

  '& .MuiInputBase-root': {
    height: '40px',
  },
}));
