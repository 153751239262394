import React from 'react';
import { Link } from 'react-router-dom';
import { Category } from 'types';
import ExpandedMenu from 'components/ExpandedMenu';
import logo from 'images/logo-full.svg';

import { FooterBox, Logo, FooterMenu } from './styles';

interface Props {
  categories: Category[];
}

function Footer({ categories }: Props) {
  return (
    <FooterBox>
      <FooterMenu>
        <Logo>
          <Link to="/">
            <img className="logo" src={logo} alt="logo" />
          </Link>
        </Logo>
        <ExpandedMenu categories={categories} />
      </FooterMenu>
    </FooterBox>
  );
}

export default Footer;
