import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

export const NextUpVideosBox = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

export const NextUp = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(4),
  minWidth: '320px',

  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(4),
  },
}));

export const VideosLayer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'scroll',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    overflowX: 'hidden',
  },

  '& > div': {
    marginRight: theme.spacing(1),
  },

  '& > div:last-child': {
    marginRight: 0,
  },
}));
