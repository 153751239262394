import React from 'react';
import { ScrollRestoration } from 'react-router-dom';
import H4 from 'components/Typo/H4';
import SponsoredBanner from 'components/Banners/SponsoredBanner';
import Categories from 'components/Categories';
import Brands from 'components/Brands';

function Home() {
  return (
    <>
      <SponsoredBanner />
      <H4>Categories</H4>
      <Categories />
      <Brands />
      <ScrollRestoration />
    </>
  );
}

export default Home;
