import React from 'react';
import { SvgIcon } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ChevronUpIcon(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M13.5303 10.5303C13.8232 10.2374 13.8232 9.76256 13.5303 9.46967L8.53033 4.46967C8.23744 4.17678 7.76256 4.17678 7.46967 4.46967L2.46967 9.46967C2.17678 9.76256 2.17678 10.2374 2.46967 10.5303C2.76256 10.8232 3.23744 10.8232 3.53033 10.5303L8 6.06066L12.4697 10.5303C12.7626 10.8232 13.2374 10.8232 13.5303 10.5303Z"
        fill="#075CAE"
      />
    </SvgIcon>
  );
}

export default ChevronUpIcon;
