import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

export const CategoryBox = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'stretch',
  flexDirection: 'row',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  flexWrap: 'wrap',
}));

export const Logo = styled('div')(() => ({
  width: '200px',
  height: '96px',
}));
