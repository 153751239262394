import React, { FC, ReactNode } from 'react';
import AppBox from './styles';

type Props = {
  children?: ReactNode;
};

// eslint-disable-next-line react/function-component-definition
const App: FC<Props> = ({ children }) => <AppBox>{children}</AppBox>;

export default App;
