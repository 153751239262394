import React, { Suspense, useMemo } from 'react';
import { defer, Await, useParams, useOutletContext } from 'react-router-dom';
import API from 'apis';
import { PageContentResponse } from 'models/generated';
import { Typography } from '@mui/material';
import VideoLoader from 'components/VideoLoader';
import { useAuth } from 'router';
import VideoThumbnail from 'components/VideoThumbnail';
import H6 from 'components/Typo/H6';
import { getCategoryIdByName } from 'utils/consts';
import { Category as CategoryType } from 'types';
import { NextUpVideosBox, NextUp, VideosLayer } from './styles';

async function videosLoader(
  categoryId: string,
  token: string
): Promise<PageContentResponse> {
  const response = await fetch(API.getContent(categoryId), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const videos = await response.json();

  return videos;
}

function loader(categoryId: string, token: string) {
  const videos = videosLoader(categoryId, token);

  return defer({ videos });
}

function NextUpVideos() {
  const { categoryId = 'unknown', videoId } = useParams();
  const [categories] = useOutletContext<[CategoryType[]]>() || [];
  const id = getCategoryIdByName(categoryId, categories) || '';
  const { token } = useAuth();

  const { data } = useMemo(() => loader(id, token), [id, token]);

  return (
    <NextUpVideosBox>
      <NextUp>
        <H6>Next Up</H6>
        <Suspense
          fallback={
            <>
              <VideoLoader />
              <VideoLoader />
            </>
          }
        >
          <Await
            resolve={data.videos}
            errorElement={<div>Could not load videos</div>}
          >
            {(resolvedVideos: PageContentResponse) => {
              const filtered = resolvedVideos.content?.filter(
                (v) => v.processed_video?.jwplayer_media_id !== videoId
              );

              if (!filtered?.length) {
                return (
                  <Typography>No more videos in current category</Typography>
                );
              }

              return (
                <VideosLayer>
                  {filtered.map((v) => (
                    <VideoThumbnail
                      video={{
                        title: v.title || 'unknown',
                        jwplayerMediaId:
                          v.processed_video?.jwplayer_media_id || '',
                        duration: v.processed_video?.duration || 0,
                        description: v.description || '',
                        nextUp: [],
                        publishDate: (v.pub_date || 0) * 1000,
                      }}
                      key={v.id}
                    />
                  ))}
                </VideosLayer>
              );
            }}
          </Await>
        </Suspense>
      </NextUp>
    </NextUpVideosBox>
  );
}

export default NextUpVideos;
