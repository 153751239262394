import entertainment from 'images/categories/entertainment.png';
import food from 'images/categories/food.png';
import home from 'images/categories/home.png';
import pets from 'images/categories/pets.png';
import travel from 'images/categories/travel.png';

import allercipes from 'images/companies/allercipes.svg';
import betterHomes from 'images/companies/better-homes.svg';
import cookinglight from 'images/companies/cookinglight.svg';
import eatingwell from 'images/companies/eatingwell.svg';
import entertainmentWeekly from 'images/companies/entertainment.svg';
import foodAndWine from 'images/companies/food-and-wine.svg';
import health from 'images/companies/health.svg';
import instyle from 'images/companies/instyle.svg';
import parents from 'images/companies/parents.svg';
import people from 'images/companies/people.svg';
import realsimple from 'images/companies/realsimple.svg';
import shape from 'images/companies/shape.svg';
import travelLeisure from 'images/companies/travel-leisure.svg';
import martaStewart from 'images/companies/marta-stewart.svg';
import { Brands, Category } from 'types';
import { JWPPlaylistListResponse } from 'models/generated';

export enum CategoryName {
  Enter = 'Entertainment',
  Food = 'Food',
  Home = 'Home',
  Travel = 'Travel',
  Pets = 'Pets',
}

const CATEGORIES = [
  { name: CategoryName.Enter, pic: entertainment, color: '#F2EFEC' },
  { name: CategoryName.Food, pic: food, color: '#E8C4B9' },
  { name: CategoryName.Home, pic: home, color: '#EEEDE8' },
  { name: CategoryName.Travel, pic: travel, color: '#B2DEF7' },
  { name: CategoryName.Pets, pic: pets, color: '#F3EAE7' },
];

const BRANDS: Brands[] = [
  { name: 'people', pic: people },
  { name: 'allercipes', pic: allercipes },
  { name: 'eatingwell', pic: eatingwell },
  { name: 'realsimple', pic: realsimple },
  { name: 'health', pic: health },
  { name: 'parents', pic: parents },
  { name: 'martaStewart', pic: martaStewart },
  { name: 'betterHomes', pic: betterHomes },
  { name: 'entertainmentWeekly', pic: entertainmentWeekly },
  { name: 'cookinglight', pic: cookinglight },
  { name: 'instyle', pic: instyle },
  { name: 'foodAndWine', pic: foodAndWine },
  { name: 'travelLeisure', pic: travelLeisure },
  { name: 'shape', pic: shape },
];

const mergeCategories = (catIds: JWPPlaylistListResponse): Category[] => {
  const result: Category[] = [];

  catIds.playlists?.forEach((catId) => {
    const cat = CATEGORIES.find(
      (c) => c.name.toLowerCase() === catId.metadata?.title?.toLowerCase()
    );

    if (cat) {
      result.push({
        id: catId.id || 'unknown',
        name: cat.name,
        color: cat.color,
        pic: cat.pic,
      });
    }
  });

  return result;
};

const setUppercaseFirstLetter = (name: string): string => {
  return name?.replace(/^./, name[0].toUpperCase());
};

const getCategoryIdByName = (
  name: string,
  cats: Category[] = []
): string | null => {
  const cat = cats.find((c) => c.name.toLowerCase() === name.toLowerCase());

  return cat?.id || '';
};

export {
  BRANDS,
  mergeCategories,
  setUppercaseFirstLetter,
  getCategoryIdByName,
};
