import React from 'react';
import DropdownMenu from 'components/DropdownMenu';
import { Category } from 'types';
import { MenuBox, MenuButton } from './styles';

interface Props {
  categories: Category[];
}

function Menu({ categories }: Props) {
  return (
    <MenuBox>
      <DropdownMenu categories={categories} />

      <MenuButton variant="text" type="button" color="secondary" href="/about">
        About
      </MenuButton>
    </MenuBox>
  );
}

export default Menu;
