import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

export const BrandsBox = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  flexWrap: 'wrap',
}));

export const Logo = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  marginRight: theme.spacing(4),
  marginBottom: theme.spacing(4),
  display: 'flex',
  alignItems: 'flex-end',

  '& img': {
    [theme.breakpoints.down('sm')]: {
      maxHeight: '36px',
    },
  },

  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
