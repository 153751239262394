import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

export const FooterBox = styled('div')(({ theme }: { theme: Theme }) => ({
  background: theme.palette.neutral.main,
  display: 'flex',
  marginLeft: `-${theme.spacing(2)}`,
  marginRight: `-${theme.spacing(2)}`,
  padding: theme.spacing(2),
  flexDirection: 'column',
  overflow: 'hidden',
}));

export const Logo = styled('div')(({ theme }: { theme: Theme }) => ({
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: '151px',

  [theme.breakpoints.up('md')]: {
    marginTop: 0,
  },
}));

export const FooterMenu = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.border.header}`,
  paddingBottom: theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const SubFooter = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  minHeight: '100px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const HmnTitle = styled('p')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  margin: 0,
}));

export const HmnAdress = styled('p')(({ theme }: { theme: Theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  marginTop: theme.spacing(1),
}));

export const HmnInfo = styled('div')(({ theme }: { theme: Theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },

  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(4),
    paddingLeft: 0,
  },
}));

export const HmnSocials = styled('div')(({ theme }: { theme: Theme }) => ({
  marginLeft: `-${theme.spacing(1)}`,
}));

export const Contacts = styled('div')(({ theme }: { theme: Theme }) => ({
  marginTop: theme.spacing(0),
  marginLeft: theme.spacing(10),

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(4),
    marginLeft: 0,
  },

  '& > p': {
    fontWeight: 400,
    fontSize: '14px',
    color: theme.palette.text.primary,

    '& > a': {
      color: theme.palette.text.secondary,
      textDecoration: 'none',
    },
  },
}));
