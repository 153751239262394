import React from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Category } from 'types';

import { CategoriesBox, Logo, Title, LinkBox } from './styles';

interface Props {
  hideId?: string;
}

function Categories({ hideId }: Props) {
  const [categories = []] = useOutletContext<[Category[]]>() || [];
  const filtered = hideId
    ? categories.filter((c) => c.name.toLowerCase() !== hideId.toLowerCase())
    : categories;

  return (
    <CategoriesBox>
      <Stack
        spacing={{ xs: 1, sm: 2, md: 4 }}
        direction={{ xs: 'row', sm: 'row' }}
      >
        {filtered.map((cat) => (
          <LinkBox key={cat.name}>
            <Link to={`/categories/${cat.name.toLowerCase()}`}>
              <Logo
                className="Logo"
                sx={{ backgroundImage: `url(${cat.pic})` }}
                color={cat.color}
              />
              <Title>{cat.name}</Title>
            </Link>
          </LinkBox>
        ))}
      </Stack>
    </CategoriesBox>
  );
}

export default Categories;
