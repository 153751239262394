import React from 'react';
import { useParams, Link, ScrollRestoration } from 'react-router-dom';
import { Button } from '@mui/material';
import Categories from 'components/Categories';
import VideoComponent from 'components/Video';
import ChevronLeftIcon from 'components/Icons/ChevronLeftIcon';
import H5 from 'components/Typo/H5';

function Video() {
  const { categoryId } = useParams();

  return (
    <>
      <Link to={`/categories/${categoryId}`}>
        <Button
          variant="text"
          color="secondary"
          startIcon={<ChevronLeftIcon />}
          sx={{ textTransform: 'capitalize' }}
        >
          {categoryId || 'unknown'}
        </Button>
      </Link>
      <VideoComponent />

      <H5>Other categories</H5>
      <Categories hideId={categoryId} />
      <ScrollRestoration />
    </>
  );
}

export default Video;
