/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';

export default function componentLoader(
  lazyComponent: () => Promise<any>,
  attemptsLeft: number
): Promise<any> {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // retry after 1000 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            Sentry.captureMessage(error, { level: 'error' });
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1000);
      });
  });
}
