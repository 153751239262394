export default function getTimeDuration(durationInSeconds: number): string {
  const S = 1;
  const M = S * 60;

  if (durationInSeconds < M) {
    return `${durationInSeconds}s`;
  }

  const min = Math.floor(durationInSeconds / M);
  const sec = durationInSeconds - min * 60;
  return `${min}m ${sec}s`;
}
