import React from 'react';
import { Link } from 'react-router-dom';
import H4 from 'components/Typo/H4';

function NoMatch() {
  return (
    <>
      <H4>Not found</H4>
      <Link to="/">Go to the home page</Link>
    </>
  );
}

export default NoMatch;
