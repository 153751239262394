import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BurgerMenu from 'components/BurgerMenu';
import { Category } from 'types';
import Menu from 'components/Menu';
import logo from 'images/logo-full.svg';
import SearchIcon from 'components/Icons/SearchIcon';
import {
  Box,
  IconButton,
  InputAdornment,
  Modal,
  debounce,
} from '@mui/material';
import CrossIcon from 'components/Icons/CrossIcon';
import ArrowLeftIcon from 'components/Icons/ArrowLeftIcon';
import SearchResults from 'components/SearchResults';

import {
  HeaderBox,
  Logo,
  BurgerMenuStyled,
  MainMenuStyled,
  ModalBox,
  Input,
} from './styles';

interface Props {
  categories: Category[];
}

function Header({ categories }: Props) {
  const location = useLocation();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const handleOpenSearch = () => {
    setOpenSearchModal(true);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
  };
  const handleCloseSearch = () => setOpenSearchModal(false);

  const debouncedSearch = debounce((str) => {
    setQuery(str);
  }, 1000);

  const [query, setQuery] = useState('');
  const handleClearSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };
  const handleSearch = (str: string) => {
    debouncedSearch(str);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setQuery('');
    handleClearSearch();
    handleCloseSearch();
  }, [location]);

  return (
    <>
      <HeaderBox>
        <BurgerMenuStyled>
          <BurgerMenu categories={categories} />
        </BurgerMenuStyled>
        <Box sx={{ display: 'flex' }}>
          <Logo>
            <Link to="/">
              <img className="logo" src={logo} alt="logo" />
            </Link>
          </Logo>
          <MainMenuStyled>
            <Menu categories={categories} />
          </MainMenuStyled>
        </Box>
        <Box>
          <IconButton onClick={handleOpenSearch} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Box>
      </HeaderBox>
      <Modal open={openSearchModal} onClose={handleCloseSearch}>
        <ModalBox>
          <Input
            autoComplete="false"
            required
            inputRef={inputRef}
            autoFocus
            onChange={(newValue) => {
              handleSearch(newValue.target.value);
            }}
            variant="standard"
            color="secondary"
            fullWidth
            placeholder="Search videos..."
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={handleCloseSearch} aria-label="close">
                    <ArrowLeftIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClearSearch}
                    aria-label="clear"
                    sx={{ visibility: query ? 'visible' : 'hidden' }}
                  >
                    <CrossIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {query && <SearchResults query={query} />}
        </ModalBox>
      </Modal>
    </>
  );
}

export default Header;
