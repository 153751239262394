import { Button, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

export const MenuBox = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },

  [theme.breakpoints.down('sm')]: {
    marginLeft: `-${theme.spacing(2)}`,
  },
}));

export const MenuButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  textTransform: 'none',
  display: 'flex',
  textAlign: 'left',
  justifyContent: 'flex-start',
  color: theme.palette.text.primary,
  padding: '12px 16px',

  '&:hover': {
    background: theme.palette.primary.light,
  },
}));

export const MenuPopover = styled(Menu)(({ theme }: { theme: Theme }) => ({
  '& .MuiPaper-root': {
    width: '100%',
  },

  '& .custom-link': {
    color: theme.palette.text.primary,
  },
}));
