import 'abortcontroller-polyfill';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ThemeProvider } from '@mui/material/styles';
import env from 'environment';
import router, { AuthProvider } from 'router';
import './index.css';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

const { PRODUCTION } = env();

Sentry.init({
  dsn: 'https://8eb848b9f9b54f26846a771ab1290e02@o4504168977203200.ingest.sentry.io/4504168978513920',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  environment: PRODUCTION ? 'production' : 'dev',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

if (!PRODUCTION) {
  // eslint-disable-next-line no-console
  reportWebVitals(console.log);
}
