import {
  Button,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import ArrowDownIcon from 'components/Icons/ArrowDownIcon';
import ArrowUpIcon from 'components/Icons/ArrowUpIcon';
import BurgerIcon from 'components/Icons/BurgerIcon';
import CrossIcon from 'components/Icons/CrossIcon';
import React from 'react';
import { Link } from 'react-router-dom';
import { Category } from 'types';
import { MenuBox, MenuButton, MenuPopover } from './styles';

interface Props {
  categories: Category[];
}

function BurgerMenu({ categories }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget.parentElement);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenList(false);
  };

  const [openList, setOpenList] = React.useState(false);

  const handleOpenList = () => {
    setOpenList(!openList);
  };

  return (
    <MenuBox>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {open ? <CrossIcon /> : <BurgerIcon />}
      </Button>
      <MenuPopover
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <List>
          <ListItemButton onClick={handleOpenList}>
            <ListItemText primary="Categories" />
            {openList ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </ListItemButton>
          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {categories.map((cat) => (
                <Link
                  to={`/categories/${cat.name.toLowerCase()}`}
                  key={cat.name}
                >
                  <ListItemButton onClick={handleClose} sx={{ pl: 4 }}>
                    <ListItemText className="custom-link" primary={cat.name} />
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Collapse>
        </List>
        <Divider />

        <MenuButton
          variant="text"
          type="button"
          color="secondary"
          href="/about"
        >
          About Us
        </MenuButton>
      </MenuPopover>
    </MenuBox>
  );
}

export default BurgerMenu;
