// eslint-disable
import React, { Suspense, useMemo } from 'react';
import { defer, Await, useParams, useOutletContext } from 'react-router-dom';
import { PageContentResponse } from 'models/generated';
import VideoLoader from 'components/VideoLoader';
import VideoThumbnail from 'components/VideoThumbnail';
import { Category as CategoryType } from 'types';
import { getCategoryIdByName } from 'utils/consts';
import { CategoryBox } from './styles';

interface JWVideo {
  playlist: {
    mediaid: string;
    title: string;
    description: string;
    pubdate: number;
    duration: number;
  }[];
}

async function videosLoader(categoryId: string): Promise<PageContentResponse> {
  const jwtVideos = await fetch(
    `https://cdn.jwplayer.com/v2/playlists/${categoryId}?format=json`
  ).then((response) => response.json() as Promise<JWVideo>);

  return {
    content: jwtVideos.playlist.map((video, index) => {
      return {
        id: index,
        title: video.title,
        pub_date: video.pubdate,
        description: video.description,
        processed_video: {
          duration: video.duration,
          jwplayer_media_id: video.mediaid,
        },
      };
    }),
  };
}

function loader(categoryId: string) {
  const videos = videosLoader(categoryId);

  return defer({ videos });
}

function Category() {
  const { categoryId = 'unknown' } = useParams();
  const [categories] = useOutletContext<[CategoryType[]]>() || [];
  const id = getCategoryIdByName(categoryId, categories) || '';

  const { data } = useMemo(() => loader(id), [id]);

  return (
    <CategoryBox>
      <Suspense fallback={<VideoLoader />}>
        <Await
          resolve={data.videos}
          errorElement={<div>Could not load videos</div>}
        >
          {(resolvedVideos: PageContentResponse) => (
            <>
              {resolvedVideos.content?.map((v) => (
                <VideoThumbnail
                  video={{
                    title: v.title || 'unknown',
                    jwplayerMediaId: v.processed_video?.jwplayer_media_id || '',
                    duration: v.processed_video?.duration || 0,
                    description: v.description || '',
                    nextUp: [],
                    publishDate: (v.pub_date || 0) * 1000,
                  }}
                  key={v.id}
                />
              ))}
              {resolvedVideos.content?.map((v) => (
                <VideoThumbnail key={`blank${v.id}`} />
              ))}
            </>
          )}
        </Await>
      </Suspense>
    </CategoryBox>
  );
}

export default Category;
