import React from 'react';
import { SvgIcon } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ChevronLeftIcon(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="16" height="18" viewBox="0 0 16 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.5303 3.96967C10.2374 3.67678 9.76256 3.67678 9.46967 3.96967L4.46967 8.96967C4.17678 9.26256 4.17678 9.73744 4.46967 10.0303L9.46967 15.0303C9.76256 15.3232 10.2374 15.3232 10.5303 15.0303C10.8232 14.7374 10.8232 14.2626 10.5303 13.9697L6.06066 9.5L10.5303 5.03033C10.8232 4.73744 10.8232 4.26256 10.5303 3.96967Z"
        fill="#9BA8BB"
      />
    </SvgIcon>
  );
}

export default ChevronLeftIcon;
