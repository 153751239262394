import { Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { BRANDS } from 'utils/consts';

import { BrandsBox, Logo } from './styles';

function Brands() {
  return (
    <>
      <Typography sx={{ marginTop: 6 }} variant="h6">
        Featuring content from brands you know & love
      </Typography>
      <BrandsBox>
        {BRANDS.map((cat) => (
          <Fragment key={cat.name}>
            <Logo>
              <img className="logo" src={cat.pic} alt="logo" />
            </Logo>
          </Fragment>
        ))}
      </BrandsBox>
    </>
  );
}

export default Brands;
