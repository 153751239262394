import React from 'react';
import { ScrollRestoration } from 'react-router-dom';
import H4 from 'components/Typo/H4';
import { Typography } from '@mui/material';
import Brands from 'components/Brands';
import SponsoredBanner from 'components/Banners/SponsoredBanner';

function About() {
  return (
    <>
      <H4>About Us</H4>
      <SponsoredBanner />
      <Typography>
        Welcome to RelaxStreamTV - a new way for patients, caregivers and
        companions to enjoy FREE premium entertainment from brands you know and
        love - courtesy of our partners at Dotdash Meredith. Simply choose the
        content category you’d like to watch, click on the corresponding image
        to begin streaming, and return to the main page to select a new category
        &nbsp;– all from your personal mobile phone or tablet device.
      </Typography>
      <Brands />
      <ScrollRestoration />
    </>
  );
}

export default About;
