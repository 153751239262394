import React from 'react';
import { SvgIcon } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SearchIcon(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="20" height="20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16C10.8859 16 12.5977 15.2542 13.8564 14.0414C13.8827 14.0072 13.9115 13.9742 13.9429 13.9429C13.9742 13.9115 14.0072 13.8827 14.0414 13.8564C15.2542 12.5977 16 10.8859 16 9C16 5.13401 12.866 2 9 2ZM16.0319 14.6177C17.2635 13.078 18 11.125 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C11.125 18 13.078 17.2635 14.6177 16.0319L18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L16.0319 14.6177Z"
        fill="#9BA8BB"
      />
    </SvgIcon>
  );
}

export default SearchIcon;
