import React, { MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowDownIcon from 'components/Icons/ArrowDownIcon';
import { Category } from 'types';
import { MenuButton, MenuPopover } from './styles';

interface Props {
  categories: Category[];
}

function DropdownMenu({ categories }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <MenuButton
        variant="text"
        aria-describedby={id}
        type="button"
        onClick={handleClick}
        color="secondary"
        endIcon={<ArrowDownIcon />}
      >
        Categories
      </MenuButton>
      <MenuPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {categories.map((cat) => (
          <Link key={cat.name} to={`/categories/${cat.name.toLowerCase()}`}>
            <MenuButton
              variant="text"
              aria-describedby={id}
              type="button"
              onClick={handleClick}
              color="secondary"
              fullWidth
            >
              {cat.name}
            </MenuButton>
          </Link>
        ))}
      </MenuPopover>
    </>
  );
}

export default DropdownMenu;
