import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

const OutletBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  flex: 'auto',
  paddingTop: theme.spacing(1),
}));

export default OutletBox;
