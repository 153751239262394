import { Box, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { Theme } from 'theme';

export const CategoriesBox = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(8),
  overflowX: 'scroll',
}));

export const Logo = styled('div')(({ color }: { color: string }) => ({
  width: '160px',
  height: '180px',
  backgroundPosition: 'center',
  backgroundSize: '100%',
  transition: 'all .3s ease',
  borderRadius: '8px',
  position: 'relative',

  '&:after': {
    content: '" "',
    position: 'absolute',
    width: '90%',
    height: '40px',
    top: '-10px',
    left: '5%',
    background: color,
    borderRadius: '8px',
    zIndex: -1,
  },

  '&:before': {
    content: '" "',
    position: 'absolute',
    width: '80%',
    height: '40px',
    top: '-20px',
    left: '10%',
    background: alpha(color, 0.5),
    borderRadius: '8px',
    zIndex: -2,
  },
}));

export const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.text.primary,
  marginTop: theme.spacing(2),
}));

export const LinkBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  alignContent: 'flex-start',

  '& > a': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: 1.2,
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },

  '&:hover .Logo': {
    backgroundSize: '110%',
  },
}));
