import { Skeleton } from '@mui/material';
import React from 'react';

import VideoLoaderBox from './styles';

function VideoLoader() {
  return (
    <VideoLoaderBox>
      <Skeleton
        variant="rounded"
        width="100%"
        height={194}
        sx={{ marginBottom: 1 }}
      />
      <Skeleton variant="rounded" width="90%" height={30} />
    </VideoLoaderBox>
  );
}

export default VideoLoader;
