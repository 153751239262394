import React, { useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';
import API from 'apis';
import './index.css';
import Home from 'pages/Home';
import About from 'pages/About';
import NoMatch from 'pages/NoMatch';
import Category from 'pages/Category';
import Video from 'pages/Video';
import Layout from 'components/Layout';

interface AuthContextType {
  token: string;
  signin: () => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = React.useState<string>('');

  const signin = async () => {
    const start = performance.now();

    try {
      const fetchToken = await fetch(API.login());

      if (!fetchToken?.ok) {
        throw new Error(
          `status: ${fetchToken.status}, type: ${fetchToken.type}, text: "${fetchToken.statusText}"`
        );
      }

      const data = await fetchToken.json();

      if (data.token) {
        setToken(data.token);
      }
    } catch (error) {
      setToken('no');
      Sentry.setContext('request data', {
        time: `${performance.now() - start}ms`,
        info: error,
      });
      Sentry.captureMessage('Failed to get token', { level: 'error' });
    }
  };

  const value = useMemo(() => ({ token, signin }), [token]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();

  if (!auth.token) {
    auth.signin();
    return <div />;
  }

  return children;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <RequireAuth>
        <Layout />
      </RequireAuth>
    ),
    children: [
      {
        element: <Home />,
        index: true,
        errorElement: <NoMatch />,
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: '*',
        element: <NoMatch />,
      },
      {
        path: 'categories/:categoryId',
        element: <Category />,
        errorElement: <NoMatch />,
      },
      {
        path: 'categories/:categoryId/:videoId',
        element: <Video />,
        errorElement: <NoMatch />,
      },
    ],
  },
]);

export default router;
