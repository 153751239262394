import { Typography } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';
import { Theme } from 'theme';

const animation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const VideoThumbnailBox = styled('div')(
  ({ theme }: { theme: Theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '100%',
    maxWidth: '374px',
    minWidth: '250px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,

    animation: `${animation} 1s ${theme.transitions.easing.easeInOut}`,

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginRight: 0,
    },

    '&:hover': {
      '.Video': {
        backgroundSize: '110%',
      },
    },

    '&.blank': {
      marginTop: 0,
      marginBottom: 0,
    },
  })
);

export const Video = styled('div')(({ pic }: { pic?: string }) => ({
  position: 'relative',
  backgroundImage: pic ? `url(${pic})` : '#fff',
  backgroundColor: pic ? `#ccc` : '#fff',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '100%',
  width: '100%',
  height: pic ? '180px' : '1px',
  borderRadius: '8px',
  transition: 'all .3s ease',
}));

export const Duration = styled('div')(({ theme }: { theme: Theme }) => ({
  background: theme.palette.neutral.dark,
  position: 'absolute',
  bottom: '10px',
  right: '10px',
  padding: theme.spacing(1),
  color: theme.palette.primary.main,
  borderRadius: '2px',
  fontWeight: 400,
  fontSize: '13px',
}));

export const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
  paddingTop: theme.spacing(1),
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: '16px',
  wordBreak: 'break-word',
}));
