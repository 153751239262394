import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getJWPlink } from 'utils/player';
import getTimeAgo from 'utils/timeAgo';
import H4 from 'components/Typo/H4';
import NextUpVideos from 'components/NextUpVideos';
import ChevronDownIcon from 'components/Icons/ChevronDownIcon';
import ChevronUpIcon from 'components/Icons/ChevronUpIcon';
import {
  VideoBox,
  Description,
  TimeAgo,
  PlayerBox,
  ButtonMore,
} from './styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const jwplayer: any;

function Video() {
  const showExpand = useRef(false);
  const location = useLocation();
  const { video } = location.state || {};

  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const { jwplayerMediaId } = video || {};

  useEffect(() => {
    if (jwplayerMediaId) {
      jwplayer('jwplayer').setup({
        playlist: [
          {
            file: getJWPlink(jwplayerMediaId),
          },
        ],
      });
    }
  }, [jwplayerMediaId]);

  if (!video) {
    return <p>Video not found</p>;
  }

  const { title, description, publishDate } = video;
  showExpand.current = description.length > 100 && window.innerWidth < 1200;

  return (
    <VideoBox>
      <PlayerBox>
        <div id="jwplayer" />
        <H4 sx={{ marginTop: 2 }}>{title}</H4>
        <TimeAgo>{getTimeAgo(publishDate, false)}</TimeAgo>
        <Description
          expanded={expanded || !showExpand.current}
          onClick={handleChange}
        >
          {description}
        </Description>
        {showExpand.current && (
          <div>
            <ButtonMore
              onClick={handleChange}
              variant="text"
              endIcon={expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            >
              {expanded ? 'Hide' : 'See more'}
            </ButtonMore>
          </div>
        )}
      </PlayerBox>

      <NextUpVideos />
    </VideoBox>
  );
}

export default Video;
