import env from 'environment';

const { uri } = env();

const api = {
  login: () => `${uri}/login/code?code=streaming`,
  getPlaylists: () => `${uri}/jwplayer/playlist`,
  getContent: (category: string, page: number = 0, size: number = 1000) => {
    return `${uri}/content?uploadedToJwp=true&available=true&page=${page}&size=${size}&jwp_playlist_ids=${category}`;
  },
  search: (query: string, page: number = 0, size: number = 10) => {
    return `${uri}/content?query=${query}&page=${page}&size=${size}&sort=pub_date,desc&order=desc&title=true&keywords=true&description=true&subtitles=true&uploadedToJwp=true&available=true`;
  },
};

export default api;
