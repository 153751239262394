import { Box, Button, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

export const MenuBox = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },

  [theme.breakpoints.down('sm')]: {
    marginLeft: `-${theme.spacing(2)}`,
  },
}));

export const MenuButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  textTransform: 'none',
  display: 'flex',
  textAlign: 'left',
  justifyContent: 'flex-start',
  color: theme.palette.text.primary,
  padding: '12px 16px',

  '&:hover': {
    background: theme.palette.primary.light,
  },
}));

export const MenuPopover = styled(Popover)(() => ({
  '& .MuiPaper-root': {
    minWidth: '200px',
  },
}));

export const BigMenu = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  marginRight: theme.spacing(8),

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const SmallMenu = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));
