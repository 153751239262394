import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

const AppBox = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: 0,
  minHeight: '100%',

  [theme.breakpoints.down('lg')]: {
    minHeight: '100vh',
  },
  [theme.breakpoints.down('xl')]: {
    minHeight: '100vh',
  },
  [theme.breakpoints.up('xl')]: {
    minHeight: '100vh',
  },
}));

export default AppBox;
