import React, { ReactNode } from 'react';
import { Link, useParams } from 'react-router-dom';
import getTimeDuration from 'utils/getTimeDuration';
import { getJWPThumbnailLink } from 'utils/player';
import { VideoThumbnailBox, Video, Duration, Title } from './styles';

interface Props {
  video?: {
    title: string;
    jwplayerMediaId: string;
    duration: number;
    description: string;
    nextUp?: [];
    publishDate?: number;
  };
  children?: ReactNode;
}

function VideoThumbnail({ video, children }: Props) {
  const { categoryId } = useParams();
  if (!video) {
    return (
      <VideoThumbnailBox className="blank">
        {!children ? <Video /> : children}
      </VideoThumbnailBox>
    );
  }

  const { title, jwplayerMediaId, duration } = video;

  return (
    <VideoThumbnailBox>
      <Link
        to={`/categories/${categoryId}/${jwplayerMediaId}`}
        state={{ video }}
      >
        <Video pic={getJWPThumbnailLink(jwplayerMediaId)} className="Video">
          <Duration>{getTimeDuration(duration)}</Duration>
        </Video>
        <Title>{title}</Title>
      </Link>
    </VideoThumbnailBox>
  );
}

export default VideoThumbnail;
