import React from 'react';
import { SvgIcon } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function BurgerIcon(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="26" height="18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M0 1C0 0.447715 0.447715 0 1 0H25C25.5523 0 26 0.447715 26 1C26 1.55228 25.5523 2 25 2H1C0.447715 2 0 1.55228 0 1ZM0 9C0 8.44772 0.447715 8 1 8H25C25.5523 8 26 8.44772 26 9C26 9.55229 25.5523 10 25 10H1C0.447715 10 0 9.55229 0 9ZM0 17C0 16.4477 0.447715 16 1 16H25C25.5523 16 26 16.4477 26 17C26 17.5523 25.5523 18 25 18H1C0.447715 18 0 17.5523 0 17Z"
        fill="#9BA8BB"
      />
    </SvgIcon>
  );
}

export default BurgerIcon;
