import React from 'react';
import logo from 'images/banners/RelaxStreamTV_730x400.webp';
import logoBig from 'images/banners/RelaxStreamTV_1360x400.webp';
import BannerBox from './styles';

function Banner() {
  return (
    <BannerBox>
      <img className="logo" src={logo} alt="logo" loading="lazy" />
      <img className="logo-big" src={logoBig} alt="logo" loading="lazy" />
    </BannerBox>
  );
}

export default Banner;
