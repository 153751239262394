import React from 'react';
import { SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';

const Icon = styled(SvgIcon)(() => ({
  width: '161px',
  height: '139px',
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SearchBigIcon(props: any) {
  return (
    <Icon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      width="161px"
      height="139px"
      viewBox="0 0 161 139"
      fill="none"
    >
      <path
        d="M53.5 139C82.7711 139 106.5 115.271 106.5 86C106.5 56.7289 82.7711 33 53.5 33C24.2289 33 0.5 56.7289 0.5 86C0.5 115.271 24.2289 139 53.5 139Z"
        fill="#F0F8FF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M158.5 128L121.914 91.4138M137.307 54.4037C137.307 83.3454 113.845 106.807 84.9037 106.807C55.9619 106.807 32.5 83.3454 32.5 54.4037C32.5 25.4619 55.9619 2 84.9037 2C113.845 2 137.307 25.4619 137.307 54.4037Z"
        stroke="#9BA8BB"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
}

export default SearchBigIcon;
