import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

function H6(props: TypographyProps) {
  const { sx } = props;
  return (
    <Typography
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      variant="h6"
      sx={{
        // eslint-disable-next-line react/jsx-props-no-spreading
        ...sx,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '120%',
      }}
    />
  );
}

export default H6;
