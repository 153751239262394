import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

function H4(props: TypographyProps) {
  const { sx } = props;
  return (
    <Typography
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      variant="h4"
      sx={{
        // eslint-disable-next-line react/jsx-props-no-spreading
        ...sx,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '120%',
        wordBreak: 'break-all',
      }}
    />
  );
}

export default H4;
