import { keyframes, styled } from '@mui/material/styles';
import { Theme } from 'theme';

const animation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const BannerBox = styled('div')(({ theme }: { theme: Theme }) => ({
  background: theme.palette.neutral.main,
  minHeight: '250px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(1),
  overflow: 'hidden',

  [theme.breakpoints.up('xs')]: {
    minHeight: '200px',
  },

  [theme.breakpoints.up('md')]: {
    minHeight: '380px',
  },

  '& .logo': {
    width: '100%',
    animation: `${animation} 1s ${theme.transitions.easing.easeInOut}`,

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },

    '&-big': {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        width: '100%',
        display: 'block',
        animation: `${animation} 1s ${theme.transitions.easing.easeInOut}`,
      },
    },
  },
}));

export default BannerBox;
