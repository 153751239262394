import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

export const MenuBox = styled('div')(({ theme }: { theme: Theme }) => ({
  background: theme.palette.primary.light,
  display: 'flex',
}));

export const MenuButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  textTransform: 'none',
  display: 'flex',
  textAlign: 'left',
  justifyContent: 'flex-start',
  color: theme.palette.text.primary,
  padding: '12px 16px',

  '&:hover': {
    background: theme.palette.neutral.main,
  },
}));
