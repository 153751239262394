import React, { memo, Suspense, useMemo } from 'react';
import { defer, Await } from 'react-router-dom';
import API from 'apis';
import { PageContentResponse } from 'models/generated';
import { Typography } from '@mui/material';
import VideoLoader from 'components/VideoLoader';
import { useAuth } from 'router';
import VideoThumbnail from 'components/VideoThumbnail';
import SearchBigIcon from 'components/Icons/SearchBigIcon';
import { SearchResultsBox, VideosLayer, NoResults } from './styles';

async function videosLoader(
  token: string,
  query: string
): Promise<PageContentResponse> {
  const response = await fetch(API.search(query), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const videos: PageContentResponse = await response.json();

  return videos;
}

function loader(query: string, token: string) {
  const videos = videosLoader(token, query);

  return defer({ videos });
}

interface Props {
  query: string;
}

function SearchResults({ query }: Props) {
  const { token } = useAuth();

  const { data } = useMemo(() => loader(query, token), [query, token]);

  if (!query) {
    return <div />;
  }

  return (
    <SearchResultsBox>
      <Suspense
        fallback={
          <>
            <VideoLoader />
            <VideoLoader />
          </>
        }
      >
        <Await
          resolve={data.videos}
          errorElement={<div>Could not load videos</div>}
        >
          {(resolvedVideos: PageContentResponse) => {
            if (!resolvedVideos.content?.length) {
              return (
                <NoResults>
                  <SearchBigIcon width="161px" height="161px" />
                  <Typography sx={{ marginTop: 2 }}>
                    Sorry, no results found for “{query}”. Try something
                    different.
                  </Typography>
                </NoResults>
              );
            }

            return (
              <VideosLayer>
                {resolvedVideos.content.map((v) => (
                  <VideoThumbnail
                    video={{
                      title: v.title || 'unknown',
                      jwplayerMediaId:
                        v.processed_video?.jwplayer_media_id || '',
                      duration: v.processed_video?.duration || 0,
                      description: v.description || '',
                      nextUp: [],
                      publishDate: (v.pub_date || 0) * 1000,
                    }}
                    key={v.id}
                  />
                ))}
                {resolvedVideos.content?.map((v) => (
                  <VideoThumbnail key={`blank${v.id}`} />
                ))}
              </VideosLayer>
            );
          }}
        </Await>
      </Suspense>
    </SearchResultsBox>
  );
}

export default memo(SearchResults);
