import { styled } from '@mui/material/styles';
import { Theme } from 'theme';
import { Button } from '@mui/material';

export const VideoBox = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    maxHeight: '60vh',
  },
}));

export const PlayerBox = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  flex: 1,
}));

export const Description = styled('p')(
  ({ expanded }: { expanded: boolean }) => ({
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    margin: 0,
    maxHeight: expanded ? '200px' : '50px',
    overflow: expanded ? 'visible' : 'hidden',
    textOverflow: 'ellipsis',
    transition: 'all .3s ease',
  })
);

export const TimeAgo = styled('p')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.text.disabled,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: '0em',
  margin: 0,
  marginBottom: theme.spacing(2),
}));

export const ButtonMore = styled(Button)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.light,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  textTransform: 'none',
  paddingLeft: 0,
  justifyContent: 'flex-start',
}));
