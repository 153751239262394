import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Theme {
    border: {
      header: React.CSSProperties['color'];
      footer: React.CSSProperties['color'];
    };
  }

  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }

  interface Palette {
    primary: Palette['primary'];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
  interface ThemeOptions {
    border?: {
      header?: React.CSSProperties['color'];
      footer?: React.CSSProperties['color'];
    };
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1600,
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  border: {
    header: '#D7E3EF',
  },
  palette: {
    text: {
      primary: '#131A25',
      secondary: '#075CAE',
      disabled: '#657793',
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#075CAE',
      main: '#8f99cc',
      dark: '#4f5a8f',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    neutral: {
      main: '#F0F8FF',
      contrastText: '#fff',
      dark: '#131A25',
    },
    success: {
      main: '#18e122',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

export type Theme = typeof theme;

export default theme;
