import React from 'react';
import { SvgIcon } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ChevronDownIcon(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="16" height="18" viewBox="0 0 16 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M13.5303 6.60199C13.8232 6.89489 13.8232 7.36976 13.5303 7.66265L8.53033 12.6627C8.23744 12.9555 7.76256 12.9555 7.46967 12.6627L2.46967 7.66265C2.17678 7.36976 2.17678 6.89489 2.46967 6.60199C2.76256 6.3091 3.23744 6.3091 3.53033 6.60199L8 11.0717L12.4697 6.60199C12.7626 6.3091 13.2374 6.3091 13.5303 6.60199Z"
        fill="#075CAE"
      />
    </SvgIcon>
  );
}

export default ChevronDownIcon;
