import React from 'react';
import { SvgIcon } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ArrowUpIcon(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="24" height="24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M18.7076 15.7071C19.0981 15.3166 19.0981 14.6834 18.7076 14.2929L12.7076 8.29289C12.3171 7.90237 11.6839 7.90237 11.2934 8.29289L5.29338 14.2929C4.90286 14.6834 4.90286 15.3166 5.29338 15.7071C5.68391 16.0976 6.31707 16.0976 6.70759 15.7071L12.0005 10.4142L17.2934 15.7071C17.6839 16.0976 18.3171 16.0976 18.7076 15.7071Z"
        fill="#9BA8BB"
      />
    </SvgIcon>
  );
}

export default ArrowUpIcon;
